
.four-o-four {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - #{$footer-height-standard} - #{$header-height-standard} - 40px);
  max-height: calc(100vh - #{$footer-height-standard} - #{$header-height-standard} - 40px);

  @include e(background){
    position: fixed;
    z-index: 0;
    top: $header-height-standard;
    left: $leftnav-width-collapsed;
    height: 100%;
    width: calc(100vw - #{$leftnav-width-collapsed} - #{$rightbar-width-standard});
    object-fit: cover;
  }

  .card {
    position: relative;
    z-index: 2;
    top: 0;
    max-width: 500px;
    img {
      width: 14vw;
    }
    @media screen and (max-width: 768px) {
      max-width: 50vw;
      img {
        width: 30vw;
      }
    }
    button > a {
      color: white;
    }
  }

}