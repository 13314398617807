
.create-group {
  @extend .show-scroll;
  max-height: 70vh;

  h3 {
    font-size: 1.1em;
  }

  #group-instructions-button {
    background: none !important;
    border: none;
    padding: 0 !important;
    margin-left: 0.5rem;
    color: white;
    text-decoration: none;
    cursor: pointer;
    font-size: 0.9em;
  }

  #group-instructions-button:hover {
    text-decoration: underline !important;
  }

  .group-help-header {
    padding: 0.2rem !important;
  }

  .group-instructions-tooltip {
    position: absolute;
    display: block;
    width: 25rem;
    background-color: #132434;
    color: white;
    font-size: 1rem;
    padding: 0.5em 1em;
    border-radius: 12px;
    z-index: 100;

    ul {
      list-style: disc !important;
      padding: 0 1rem 1rem 1rem;
    }
  }

  .group-instructions-tooltip-header {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .tooltip-close {
    width: 55px;
  }

  .tooltip-close:after {
    content: "< Back";
    font-size: 1.25em;
    color: #132434;
  }

  .group-circle {
    margin-left: 0.5rem;
    cursor: pointer;
    font-size: 1.5rem;
    color: white;
  }

  font-size: 1.15em;

  min-width: 400px;

  .card__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: $component-padding-sm;
  }

  @include e(name){
    display: flex;
    justify-content: center;
    padding: $component-padding-lg $component-padding-sm;
    input {
      padding: $component-padding-sm $component-padding-lg;
      font-family: inherit;
      border: $border-standard;
      font-size: 1em;
      border-radius: 30px;
      width: 14em;
      outline: none;
    }
  }

  @include e(selectors){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @include e(selector){
    display: flex;
    flex-direction: column;
    align-items: center;
    border: $border-standard;
    border-radius: $radius-md;
    padding: $component-padding-sm;
    max-width: 66%;
    margin-bottom: $component-spacing-md;

    .fa-check-circle {
      color: $seafoam;
    }

    .fa-times-circle {
      color: $red;
    }

    @include e(selection){
      margin: $component-spacing-sm;
      select {
        width: 7em;
      }
    }
    @include e(last-name){
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      @include e(wrap){
        display: flex;
        align-items: center;
        input {
          width: 4em;
          margin: $component-spacing-sm;
        }
      }
    }
    @include e(message) {
      font-size: 0.9em;
      margin: $component-spacing-sm;
    }
    @include e(remove){
      margin: $component-spacing-sm;
    }
  }

  @include e(add-selector) {
    text-align: center;
    background-color: $seafoam;
    width: 24em;
    font-size: 0.9em;
    margin: auto;
    padding: $component-padding-xs $component-padding-sm;
    border-radius: 30px;
    color: $white;
    cursor: pointer;
    margin: $component-spacing-md;
  }

  @include e(message){
    margin: $component-spacing-lg;
    font-size: 0.9em;
    color: $red;
  }

  @include e(submit){
    margin: $component-spacing-md;
  }

  @include e(search){
    margin-top: $component-spacing-md;
    width: 50%;
    .search {
      border: $border-standard;
      border-radius: 30px;
      padding: $component-padding-xs $component-padding-sm;
      justify-content: center;
      input {
        border-bottom: none;
      }
      ul {
        @extend .show-scroll;
        margin-top: $component-spacing-sm;
        max-height: 20vh;
      }
    }
    @include e(adds){
      text-align: center;
      margin: $component-spacing-md;
      div {
        color: $gray;
      }
      ul {
        margin: $component-spacing-sm;
        li {
          font-size: 0.85em;
          box-sizing: border-box;
          margin: $component-spacing-xs;
          border: $border-standard;
          padding: $component-padding-xs $component-padding-sm;
          border-radius: 30px;
          width: fit-content;
          cursor: pointer;
          margin: auto;
          &:hover {
            background-color: $gray;
            color: $white;
            &::before{
              content: "× ";
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  @include e(reset-single){
    max-width: 66%;
    text-align: center;
  }
}
