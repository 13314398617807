
.panel-notes {
 
  min-width: 400px;
  max-width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: calc(80px + #{$footer-height-standard});
  box-sizing: border-box;

  @media screen and (max-width: 768px){
    min-width: calc(100vw - #{$leftnav-width-collapsed} - #{$rightbar-width-standard});
    max-width: calc(100vw - #{$leftnav-width-collapsed} - #{$rightbar-width-standard});
  }

  @include e(info){
    border-bottom: $border-standard;
    box-sizing: border-box;
    > div {
      margin: $component-spacing-xs;
      margin-left: 0;
      span {
        font-weight: bold;
      }
    }
  }

  @include e(notes){
    @extend .show-scroll;
    max-height: calc(100vh - #{$footer-height-standard} - #{$header-height-standard} - 360px);
    box-sizing: border-box;
    border-bottom: $border-standard;
  }

  @include e(note){
    padding: $component-padding-sm $component-spacing-md;
    border-bottom: $border-standard;
    overflow-wrap: break-word;
    &:last-child {
      border-bottom: none;
    }
    > * {
      margin-bottom: $component-spacing-xs;
    }
    @include e(author){
      color: $gray;
    }
    @include e(date){
      color: $gray;
    }
  }

  @include e(input){
    textarea {
      resize: none;
      width: 90%;
      height: 140px;
      border: $border-standard;
      border-radius: $radius-lg;
      margin-bottom: $component-spacing-md;
      padding: $component-padding-sm;
      box-sizing: border-box;
      font-family: 'Century Gothic';
    }
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}