
$student-view-header-height: 100px;

.student {

  font-size: 0.9em;

  @media screen and (min-width: 769px){
    height: calc(100vh - #{$header-height-standard} - #{$footer-height-standard});
  }

  .fa-check-circle {
    color: $seafoam;
  }
  .fa-exclamation-circle {
    color: $gold;
  }
  .fa-circle {
    color: $silver;
  }
  .fa-minus-circle {
    color: $gray;
  }
  .fa-times-circle {
    color: $orange;
  }

  .collapsible-list-card__item .flex-row {
    align-items: center;
  }

  @include e(left){
    @include m(collapsed){
      @extend .student__left !optional;
      @media screen and (min-width: 769px){
        max-width: 4.6em;
      }
      @media screen and (max-width: 768px){
        height: auto;
        min-height: unset;
      }
    }
  }

  @include e(left-panel){
    border-right: $border-standard;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 769px){
      height: calc(100vh - #{$header-height-standard} - #{$footer-height-standard});
    }
    @include m(collapsed){
      @extend .student__left-panel !optional;
      .student__left-panel__student {
        @media screen and (min-width: 769px){
          border-bottom: none;
          display: flex;
          @include e(header){
            color: $gray;
            min-width: calc(100vh - #{$header-height-standard} - #{$footer-height-standard});
            display: flex;
            justify-content: flex-end;
            transform-origin: left;
            transform: rotate(-90deg) translate(0.5em, 2em);
            top: calc(100vh - #{$component-padding-xl});
            position: fixed;
            height: auto;
            @include e(toggle){
              transform: rotate(90deg) translateX(0.25em);
              margin-left: $component-spacing-md;
              margin-right: 0;
            }
          }
          @include e(info){
            visibility: hidden;
          }
        }
      }
      .student__left-panel__schedule {
        visibility: hidden;
      }
    }
    @include e(student){
      border-bottom: $border-standard;
      box-sizing: border-box;
      min-height: 50%;
      @include e(header){
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 75px;
        padding: 0 $component-padding-lg 0 $component-padding-xl;
        @include e(toggle){
          margin-right: $component-spacing-lg;
          color: $gray;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 2em;
          width: 2em;
        }
      }
      @include e(info){
        @extend .show-scroll;
        max-height: calc(100% - 75px);
        padding: 0 $component-padding-xl;
        @include e(item){
          margin-bottom: $component-spacing-lg;
          > div:first-child {
            color: $gray;
            font-weight: bold;
            margin-bottom: $component-spacing-sm;
          }
          @include m(notes){
            @extend .student__left-panel__student__info__item !optional;
            > div:nth-child(2){
              cursor: pointer;
              color: $orange;
              &:hover {
                color: $navy;
              }
            }
          }
        }
      }
    }
    @include e(schedule){
      height: 50%;
      @include e(header){
        padding: 0 $component-padding-xl;
        height: 60px;
        display: flex;
        align-items: center;
      }
      @include e(courses){
        @extend .show-scroll;
        max-height: calc(100% - 60px);
        @include e(item){
          padding: $component-padding-md;
          padding-left: $component-padding-xl;
          > div:first-child{
            display: flex;
            align-items: center;
            padding: $component-padding-xs;
            padding-left: 0;
            font-size: 0.85em;
            > div:first-child {
              padding-right: $component-padding-lg;
            }
            > div:nth-child(2){
              white-space: nowrap;
            }
          }
          &:nth-child(odd){
            background-color: $white;
          }
        }
      }
    }
  }

  @include e(main){
    background-color: $white;
    max-width: calc(100vw - #{$leftnav-width-collapsed} - #{$rightbar-width-standard});
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @include e(header){
      background-color: $snow;
      display: flex;
      justify-content: space-between;
      padding: $component-padding-xl $component-padding-lg 0 $component-padding-lg;
      border-bottom: $border-standard;

      @media screen and (min-width: 769px){
        min-height: $student-view-header-height;
        max-height: $student-view-header-height;
        height: $student-view-header-height;
      }
      
      @include e(tabs){
        display: flex;
      }

      @include e(buttons){
        display: flex;
        align-items: flex-end;
        margin-bottom: 1.5em;
        white-space: nowrap;
        > * {
          margin-left: $component-spacing-md;
        }
        .button {
          height: 2.75em;
          padding-left: 1.3em;
          padding-right: 1.3em;
          border: none;
          font-weight: bold;
          &:hover {
            font-weight: bold;
          }
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }

      @include e(flags){
        display: flex;
        align-items: center;
        height: 2.5em;
        border: $border-standard;
        border-radius: 5em;
        padding-left: $component-padding-md;
        background-color: $white;
        font-size: 0.95em;
        cursor: pointer;
        span {
          padding-right: $component-padding-sm;
        }
        .container {
          height: 100%;
          // width: 2.25em;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          border-left: $border-standard;
          border-radius: 0 5em 5em 0;
          padding: 0 1em 0 0.75em;
        } 
      }

      @include e(tab){
        @include m(active){
          @extend .student__main__header__tab !optional;
          background: $white;
          border: $border-standard;
          border-bottom: 1px solid $white;
          margin-bottom: -1px;
          color: $midnight;
        }
        color: $gray;
        cursor: pointer;
        margin-right: $component-spacing-md;
        padding: $component-padding-sm $component-padding-lg;
        border-bottom: none;
        border-radius: $radius-md $radius-md 0 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @media screen and (max-width: 768px){
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: calc(100vw - #{$leftnav-width-collapsed} - #{$rightbar-width-standard});
        padding: $component-padding-md;
        @include e(tab){
          margin: $component-spacing-sm;
          border: $border-standard;
          border-bottom: $border-standard;
          border-radius: $radius-md;
        }
      }
    }

    @include e(content){
      background-color: $white;
    }

    @include e(gp){
      @include e(header){
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $component-padding-lg $component-padding-xl;
        background: $white;
        border-bottom: $border-standard;

        .loading-ring {
          position: absolute;
          margin-left: 1em;
          margin-top: -0.1em;
        }

        @include e(select){
          border: none;
          background: none;
          font-size: 1.25em;
          font-weight: bold;
          padding: $component-padding-sm;
          padding-left: $component-padding-xs;
          cursor: pointer;
          outline: none;
        }

        @include e(right){
          display: flex;
          align-items: center;
        }

        @include e(status){
          span:first-child{
            color: $gray;
            font-weight: bold;
          }
          margin-right: $component-spacing-xl;
        }

        @include e(flags){
          display: flex;
          align-items: center;
          height: 2em;
          border: $border-standard;
          padding-left: $component-padding-sm;
          cursor: pointer;
          span {
            padding-right: $component-padding-sm;
          }
          .container {
            height: 100%;
            width: 2em;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.8m;
            font-weight: bold;
            border-left: $border-standard;
          }
        }
      }
      @include e(content){
        min-height: calc(100vh - #{$header-height-standard} - #{$footer-height-standard} - #{$student-view-header-height} - 76px);
      }
    }

    @include e(progress){
      border-right: $border-standard;
      min-height: 100%;
      background: $white;
      @media screen and (max-width: 768px){
        width: calc(100vw - #{$leftnav-width-collapsed} - #{$rightbar-width-standard});
        max-width: calc(100vw - #{$leftnav-width-collapsed} - #{$rightbar-width-standard});
        border-bottom: $border-standard;
        margin-bottom: $component-spacing-lg;
      }
      @include e(header){
        padding: $component-padding-lg;
        font-size: 1.1em;
        background: $snow;
        height: 60px;
        display: flex;
        align-items: center;
        @include m(cte){
          @extend .student__main__progress__header !optional;
          border-bottom: $border-standard;
        }
      }
      @include e(title){
        background: $snow;
        padding: $component-padding-md $component-padding-lg;
        border-bottom: $border-standard;
        border-top: $border-standard;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: space-between;
        i {
          font-size: 1.4em;
        }
        @include m(interactive){
          @extend .student__main__progress__title !optional;
          cursor: pointer;
        }
      }
      @include e(content-wrap){
        @extend .show-scroll;
        max-height: calc(100vh - #{$header-height-standard} - #{$footer-height-standard} - #{$student-view-header-height} - 140px);
      }
      @include e(list){
        background-color: $white;
      }
      @include e(item){
        padding: $component-padding-md $component-padding-lg;
        display: flex;
        align-items: center;
        justify-content: space-between;
        i {
          font-size: 1.1em;
        }
        > *:nth-child(2){
          display: flex;
          align-items: center;
        }
        @include e(total){
          margin-right: $component-spacing-md;
          > *:first-child{
            font-weight: bold;
            margin-right: $component-spacing-sm;
          }
        }
      }
    }

    @include e(subjects-wrapper){
      @extend .show-scroll !optional;
      @media screen and (min-width: 769px){
        max-height: calc(100vh - #{$footer-height-standard} - #{$header-height-standard} - 166px);
        overflow-y: scroll;
        overflow-x: hidden;
      }
      .flex-col--1 {
        text-align: center;
      }
    }

    @include e(generic-list){
      @include e(header){
        padding: $component-padding-lg $component-padding-xl;
        font-weight: bold;
        font-size: 1.1em;
        border-bottom: $border-standard;
        .flex-row > * {
          padding: $component-padding-md;
        }
        height: 75px;
      }
      @include e(list){
        @extend .show-scroll;
        max-height: calc(100vh - #{$header-height-standard} - #{$footer-height-standard} - 175px);
        @include e(item){
          padding: $component-padding-lg $component-padding-xl;
          &:nth-child(even){
            background-color: $snow;
          }
          > * {
            padding: 0 $component-padding-md;
          }
        }
      }
    }

    @include e(assess) {
      @include e(status){
        font-size: 1.2em;
        transform: translateX(0.72em);
      }
    }
  }
}

.flags-modal-card {
  min-width: 50vw;
  font-size: 1.2em;
  .card__content {
    @extend .show-scroll;
    overflow-x: hidden;
    min-height: 80px;
    max-height: 50vh;
    i {
      color: $gray;
      cursor: pointer;
    }
    .table__cell {
      justify-content: center;
    }
  }
}

.score-total {
  margin: $component-spacing-sm;
  white-space: nowrap;
  i {
    transform: translateY(0.04em);
    font-size: 1.15em;
  }
  @include e(left){
    font-weight: bold;
    margin-right: $component-spacing-sm;
  }
  @include e(mid){
    margin-right: $component-spacing-xs;
  }
  @include e(right){
    margin-right: $component-spacing-md;
  }
}

.student-gpa {
  @include e(icon){
    margin-left: $component-spacing-sm;
    margin-top: 0.1em;
    font-size: 0.95em;
  }
}