
.panel__filter {

  min-width: 300px;

  @media screen and (max-width: 768px){
    min-width: calc(100vw - #{$leftnav-width-collapsed} - #{$rightbar-width-standard} - 1px);
  }

  @include e(saved-view){
    @extend .show-scroll;
    height: 100%;
    font-size: 0.8em;
    border-right: $border-standard;

    h3 {
      font-size: 1.25em;
    }
    
    @media screen and (min-width: 768px){
      min-width: 350px;
    }
  }

  @include e(saved-list){
    @include e(item){
      border-top: $border-standard;
      &:last-child {
        border-bottom: $border-standard;
      }
      .flex-col--1 {
        border-left: $border-standard;
        text-align: center;
        .container {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 3em;
        }
      }
      .flex-col--4 {
        display: flex;
        align-items: center;
      }
    }
  }

  @include e(input-list){
    @extend .show-scroll;
    max-height: 140px;
    padding: $component-padding-xs;
    border: $border-standard;
    border-radius: $radius-lg;
    width: 95%;
    li {
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        color: $navy;
      }
    }
  }

  @include e(input-view){
    @extend .show-scroll;
    font-size: 0.8em;
    box-sizing: border-box;
    padding-bottom: 200px;

    h2 {
      font-size: 1.25em;
      margin-left: -0.5em;
    }
    
    @media screen and (min-width: 768px){
      min-width: 300px;
    }

    @include e(submit-area){
      position: fixed;
      bottom: $footer-height-standard;
      border-top: $border-standard;
      width: 300px;
      box-sizing: border-box;
      background-color: $white;
      button {
        font-size: 1em;
      }
    }

    input[type="text"] {
      border-radius: $radius-xl;
      border: $border-standard;
      width: 95%;
      padding: $component-padding-xs $component-padding-sm;
    }
  }

  i, .panel__filter__checkbox {
    cursor: pointer;
    color: $navy;
    &:hover {
      color: $gray;
    }
    &::after {
      position: absolute;
      font-size: 0.9em;
      background: rgba(0,0,0,0.5);
      color: $white;
      padding: 0.25em 0.5em 0.35em 0.5em;
      border-radius: $radius-xl;
      box-sizing: border-box;
      font-family: "Century Gothic";
    }
  }

  @include e(checkbox){
    &:hover::after {
      content: "Activate";
      transform: translate(-2.1em, -2em);
    }
    @include m(active){
      @extend .panel__filter__checkbox;
      &:hover::after {
        content: "Deactivate";
        transform: translate(-2.7em, -2em);
      }
    }
  }

  @include e(info-button){
    &:hover::after {
      content: "Details";
      transform: translate(-2.75em, -1.75em);
    }
  }
  @include e(edit-button){
    &:hover::after {
      content: "Edit";
      transform: translate(-2.15em, -1.75em);
    }
  }
  @include e(delete-button){
    &:hover::after {
      content: "Delete";
      transform: translate(-2.7em, -1.75em);
    }
  }
}

.filter-details-card {
  @extend .show-scroll;
  min-width: 300px;
  max-height: 90vh;
  border: none;

  @include e(title){
    border-bottom: $border-standard;
    border-top: $border-standard;
  }

  .card__content {
    min-height: 100px;
  }

  ul {
    padding: $component-padding-sm;
    background-color: $snow;
    list-style: square;
    li {
      padding: $component-padding-xs;
      padding-left: 0;
      margin-left: 1.5em;
      font-size: 0.9em;
    }
  }

}