
.blocker-page {

  @include e("background"){
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    opacity: 0.75;
    z-index: 0;
  }

  @include e(content){
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;


    @include e(card){
      max-width: 300px;
      @media screen and (max-width: 768px ){
        max-width: 40vw;
      }
    }

    @include e(card-content) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @include e(message-container){
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @include e(message){
      font-weight: bold;
      margin-bottom: $component-spacing-sm;
    }

    @include e(logo){

      max-width: 66%;
      @media screen and (max-width: 768px ){
        max-width: 75%;
      }
      margin: auto;
    }
  }

}