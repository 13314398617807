
.flags-card {

  @include e(header){
    label, input {
      cursor: pointer;
    }

    @include e(status-views){
      font-size: 0.8em;
      > div {
        display: flex;
        align-items: center;
        &:hover {
          font-weight: bold;
          cursor: pointer;
        }
      }
      input[type="checkbox"]{
        transform: translateY(0.05em);
      }
    }
    @include e(filter){
      display: flex;
      flex-direction: column;
      align-items: center;
      @include e(dropdown){
        position: absolute;
        font-size: 0.9em;
        background-color: $snow;
        border: 1px solid $navy;
        box-sizing: border-box;
        padding: $component-padding-xs;
        margin-top: 2.5em;
        input[type="checkbox"]{
          transform: translateY(0.1em);
        }
        li {
          &:hover {
            font-weight: bold;
            cursor: pointer;
          }
        }
      }
    }
  }

  @include e(flags){
    @extend .show-scroll;
    overflow-x: hidden;
    max-height: calc(100vh - #{$header-height-standard} - #{$footer-height-standard} - 175px);
    min-height: calc(100vh - #{$header-height-standard} - #{$footer-height-standard} - 175px);
  }

  @include e(table){
    @include e(header){
      font-size: 1.1em;
      @include e(sorter){
        span {
          margin-right: $component-padding-xs;
        }
      }
    }
    @include e(flag){
      font-size: 1em;
      input[type="checkbox"]{
        margin: auto;
      }
      @include e(status){
        display: flex;
        align-items: center;
        width: 100%;
        select {
          font-size: 1em;
          margin: auto;
        }
      }
      @include e(notes){
        display: flex;
        align-items: center;
        color: $gray;
        i {
          margin: 0 $component-spacing-xs 0 auto;
          padding: $component-padding-xs;
          padding-right: 0;
          cursor: pointer;
        }
        span {
          margin: 0 auto 0 0;
        }
      }
    }
  }

  @include e(footer){
    display: flex;
    justify-content: flex-end;
    padding: $component-padding-xs;
    border-top: $border-standard;
  }

}