
.resources {
  font-size: 1.1em;

  @include e(banner){
    width: calc(100% + (#{$component-padding-md} * 2));
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: $component-spacing-lg;
    img {
      z-index: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    h2 {
      position: absolute;
      z-index: 1;
      font-size: 1.5em;
      box-sizing: border-box;
      padding: $component-padding-md
    }
  }

  h3 {
    font-size: 1.15em;
    margin-bottom: $component-spacing-md;
  }

  @include e(question){
    padding: $component-padding-lg;
    span {
      font-weight: bold;
      cursor: pointer;
      color: $orange;
      &:hover {
        color: $midnight;
      }
    }
    a {
      color: $orange;
    }
  }

  @include e(knowledge){
    padding: $component-padding-lg;
  }

  @include e(browse){
    padding: $component-padding-xs $component-padding-lg;
    a {
      color: $orange;
    }
  }

  @include e(address){
    padding: $component-padding-lg;
    > * {
      margin: $component-spacing-xs 0;
    }
  }

  @include e(links){
    display: flex;
    flex-wrap: wrap;
    // max-width: calc(100vw - #{$leftnav-width-collapsed} - #{$rightbar-width-standard});
    @media screen and (max-width: 768px){
      justify-content: center;
    }
  }

  @include e(link){
    @include e(card){
      font-size: 0.9em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      color: $midnight;
      margin: $component-spacing-lg;
      margin-bottom: 0;
      padding: $component-padding-lg $component-padding-sm;
      border: $border-standard;
      border-radius: $radius-lg;
      width: 9em;
      height: 8em;
      text-align: center;
      &:hover {
        background-color: $white;
      }
      i {
        font-size: 5em;
      }
      > div > div:last-child {
        color: $orange;
      }
    }
  }
}