
.error-history {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include e(label){
    display: flex;
    align-items: center;
    padding: $component-padding-lg;
    color: $orange;
    white-space: nowrap;
    cursor: pointer;

    span {
      font-size: 0.75em;
      margin-left: $component-spacing-xs;
    }
  }

  @include e(dropdown){
    font-size: 0.9em;
    background-color: $white;
    position: fixed;
    top: $header-height-standard;
    min-width: 200px;
    max-width: 200px;
    box-sizing: border-box;
    border: $border-standard;
    border-top: none;
    border-radius: 0 0 $radius-sm $radius-sm;
    @include e(title){
      color: $red;
      box-sizing: border-box;
      font-weight: bold;
      text-align: center;
      border-bottom: $border-standard;
      padding: $component-spacing-sm;
      button {
        display: inline-block !important;
      }
    }
    @include e(list){
      @extend .show-scroll;
      max-height: 150px;
      @include e(item){
        box-sizing: border-box;
        padding: $component-spacing-sm;
        color: $orange;
        font-weight: bold;
        font-size: 0.85em;
        &:not(:last-child){
          border-bottom: $border-standard;
        }
      }
    }
  }
}