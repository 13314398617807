
.header__right__end {
  height: $header-height-standard;
  width: $rightbar-width-standard;
}

.header {

  h1 {
    font-size: 1.5em;
  }

  @include e(exports-list){
    position: absolute;
    @include e(item){
      font-size: 0.8em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @extend .search__result-list__item;
      background-color: $white;
      box-sizing: border-box;
      padding: $component-padding-xs $component-padding-sm;
      border: $border-standard;
      border-top: none;
      max-width: 121px;
      width: 121px;
      span:nth-child(2){
        background-color: $snow;
        padding: $component-padding-xs;
        border-radius: $radius-sm;
      }
      &:hover {
        background-color: $snow;
      }
      &:last-child {
        border-bottom: $border-standard;
        border-radius: 0 0 $radius-sm $radius-sm;
      }
    }
  }

  @include e(search){ //TO BE CORE
    width: 300px;
    margin: 0 $component-spacing-lg 0 $component-padding-sm;
    border: $border-standard;
    padding: $component-padding-sm;
    border-radius: 3em;
    i {
      transform: translateY(0.05em);
    }

    input {
      border-bottom: none;
    }

    @media screen and (max-width: 768px){
      width: 30vw;
    }

    .search__result-list { //TO BE CORE
      @extend .show-scroll;
      border-bottom: $border-standard;
      width: 290px;
      max-height: 200px;
      margin-top: $component-padding-sm;
    }

    @include e(result){
      width: 100%;
      // color: red;
    }
  }

  @include e(item){ //TO BE CORE
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: $header-height-standard;
    border-right: $border-standard;
    border-left: $border-standard;
    padding: 0 $component-padding-lg;
    font-size: 0.9em;
    color: $gray;
    & > * {
      margin: 0 $component-spacing-xs;
    }
    cursor: pointer;
    i {
      // font-size: 1em;
      &.fa-caret-down, &.fa-caret-up {
        // margin-bottom: -0.1em/;
      }
    }
    &.exports {
      max-width: 120px;
      width: 120px;
      border-right: none;
    }
  }

  @include e(error-message){
    position: absolute;
    top: calc(#{$header-height-standard} - 0.5px);
    margin-left: $leftnav-width-collapsed;
    width: calc(100vw - #{$leftnav-width-collapsed} - #{$rightbar-width-standard} - 1px);
    visibility: visible;
    opacity: 1;
    transition: opacity 0.25s ease;
    text-align: center;
    font-weight: bold;

    @include m(hidden){
      @extend .header__error-message;
      transition: all 0.25s ease;
      visibility: hidden;
      opacity: 0;
    }
  }

  @include e(profile-list){
    position: absolute;
    top: $header-height-standard;
    right: 0;

    @include e(item){
      background-color: $white;
      padding: $component-padding-sm $component-padding-sm;
      border: $border-standard;
      border-top: none;
      font-size: 0.9em;
      color: $navy;
      @include m(logout){
        @extend .header__profile-list__item;
        cursor: pointer;
        border-radius: 0 0 0 $radius-sm;
        font-weight: bold;
        font-size: 0.8em;
        &:hover {
          background-color: $snow;
        }
      }
    }
  }
}