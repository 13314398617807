
.groups-card {

  .button {
    &:focus {
      box-shadow: none;
    }
  }

  .card__header {
    height: 2.5em;
    .button--primary {
      font-size: 0.9em;
      border-radius: 100px;
    }
  }

  @include e(saved-group){
    border-bottom: $border-standard;
    .button {
      width: 7em;
      margin-left: $component-spacing-sm;
    }

    @include e(name){
      font-size: 0.8em;
      align-items: center;
      margin: auto 0;
    }
  }

  @include e(placeholder){
    border-bottom: $border-standard;
  }

  @include e(disclaimer){
    font-size: 0.8em;
  }
}

.groups-details-modal {
  .card {
    @extend .show-scroll;
    max-height: 90vh;
    border: none;
    .card__header {
      border: none;
    }

  }
}