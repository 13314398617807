
.panel__support {
  min-width: 400px;
  max-width: 400px;
  @media screen and (max-width: 768px){
    min-width: calc(100vw - #{$leftnav-width-collapsed} - #{$rightbar-width-standard});
    max-width: calc(100vw - #{$leftnav-width-collapsed} - #{$rightbar-width-standard});
  }
  p {
    text-align: left;
    text-indent: 3em;
  }
  textarea {
    border-radius: $radius-md;
    border: 1px solid $gray;
    outline: none;
    padding: $component-padding-sm;
    box-sizing: border-box;
  }
}