

.indicators {

  .flex-row {
    // max-width: 0;
    // justify-content: stretch;
  }

  margin-bottom: 50px;

  @include e(card){

    font-size: 1em;

    h3 {
      font-size: 1.05em;
    }

    @include m(overall){
      @extend .indicators__card !optional;
    }
    @include m(ye9){
      @extend .indicators__card !optional;
    }
    @include m(flags){
      @extend .indicators__card !optional;
    }
    @include m(student-flags){
      @extend .indicators__card !optional;
    }
    @include m(flags-table){
      @extend .indicators__card !optional;
    
      .table__row {
        border-left: none;
        border-bottom: none;
        border-right: none;
        .table__cell {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
        @include m(header){
          .table__cell {
            padding: $component-padding-sm;
          }
        }
        .table__cell:last-child{
          border-right: none;
        }
        &:last-child {
          .table__cell {
            border-bottom: none;
            &:first-child{
              border-radius: 0 0 0 $radius-md;
            }
            &:last-child {
              border-radius: 0 0 $radius-md 0;
            }
          }
        }
      }
    }
  }

  @include e(card-footer-student-count){
    display: flex;
    > div {
      flex: 1;
      padding: $component-padding-sm $component-padding-md;
      text-align: center;
      &:first-child {
        border-radius: 0 0 0 $radius-md;
      }
      &:last-child {
        border-radius: 0 0 $radius-md 0;
      }
      > div:first-child{
        font-weight: bold;
        font-size: 1.05em;
        margin-bottom: $component-spacing-xs;
      }
    }
  }
  
  @include e(pie-wrapper){
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 300px;
    height: 300px;
    @media screen and (max-width: 768px){
      width: 50vw;
      height: 50vw;
      .indicators__pie-wrapper__total {
        height: 18vw;
        width: 18vw;
      }
    }
    @include e(total){
      position: absolute;
      text-align: center;
      > div:first-child{
        font-size: 1.25em;
        margin-bottom: $component-spacing-sm;
      }
      background-color: $midnight;
      border-radius: 50%;
      height: 105px;
      width: 105px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $white;
    }
  }

  @include e(bar-wrapper){
    width: 40vw;
    height: 300px;
    margin: auto;
    @media screen and (max-width: 768px){
      width: 80vw;
      height: 66vw;
    }
  }

  @include e(flag-bars){
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: $component-spacing-md;
    margin-left: -5vw;
    > * {
      font-size: 1.2em;
      min-width: 300px;
      width: 100%;
      color: $white;
      display: flex;
      border-radius: $radius-md;
      margin-bottom: $component-spacing-sm;
      > *:first-child {
        font-weight: bold;
        margin-right: $component-spacing-md;
      }
    }
    @media screen and (max-width: 768px) {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  }

  @include e(students-with-flags){
    @include e(item){
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: $component-padding-lg;
      min-height: 140px;
      text-align: center;
      > *:first-child{
        padding: $component-padding-md $component-padding-lg;
        font-size: 2.25em;
        font-weight: bold;
      }
      > *:nth-child(2){
        margin-bottom: $component-spacing-md;
      }
    }
  }

}