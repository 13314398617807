
.panel-groups {

  font-size: 0.9em;

  @media screen and (max-width: 768px) {
    font-size: 1em;
  }

  @include e(header){
    max-width: 350px;
    max-height: 70px;
    .button--secondary {
      font-size: 0.8em;
      margin-left: 0em;
    }
    h2 {
      max-width: 200px;
      padding: $component-padding-sm;
      text-align: center;
    }
  }

  max-width: 350px;
  min-width: 350px;

  @media screen and (max-width: 768px){
    min-width: calc(100vw - #{$leftnav-width-collapsed} - #{$rightbar-width-standard});
    max-width: calc(100vw - #{$leftnav-width-collapsed} - #{$rightbar-width-standard});
  }

  @include e(no-select){
    @include e(message){
      text-align: center;
      margin: $component-spacing-sm;
    }
    @include e(link){
      display: flex;
      justify-content: center;
      .button {
        font-size: 1em;
      }
    }
    @include e(or){
      display: flex;
      justify-content: center;
      padding: $component-padding-md;
      font-weight: bold;
    }
    @include e(quick-select){
      display: flex;
      flex-direction: column;
      align-items: center;
      > *:nth-child(2) {
        margin-top: $component-spacing-md;
      }
      ul {
        @extend .show-scroll;
        margin-top: $component-spacing-md;
        max-height: 50vh;
        padding: 0 $component-padding-md;
      }
      li {
        padding: $component-padding-sm $component-padding-md;
        margin-bottom: $component-spacing-sm;
        border: $border-standard;
        border-radius: 30px;
        cursor: pointer;
        &:hover {
          background-color: $orange;
          font-weight: bold;
          color: $white;
        }
      }
    }
  }

  @include e(view-switch){
    display: flex;

    @include e(option){
      flex: 1;
      text-align: center;
      padding: $component-padding-md $component-padding-lg;
      font-size: 1.1em;
      border-bottom: 2px solid $white;
      transition: border-color 0.1s ease-in-out;
      cursor: pointer;
      &.active {
        border-color: $navy;
      }
    }
  }

  @include e(selected){
    @include e(student-view){
      @include e(search){
        font-size: 1.1em;
        padding-top: $component-padding-md;
        margin-bottom: $component-spacing-md;
        margin-left: -0.1em;
        width: 100%;
      }

      @include e(single-add-toggle){
        margin-bottom: $component-spacing-md;
        display: flex;
        align-items: center;
        white-space: nowrap;

        @include e(icon){
          display: flex;
          justify-content: center;
          align-items: center;
          color: $gray;
          margin: 0 $component-spacing-md;
          font-size: 1.2em;
          background-color: $snow;
          width: 1.8em;
          height: 1.8em;
          border-radius: 50%;
          cursor: pointer;
          &::after {
            position: absolute;
            font-size: 0.75em;
            color: $gray;
          }
          i {
            transform: translate(0.025em,-0.05em);
          }
          &:hover {
            color: $white;
            background-color: $orange;
            &::after {
              content: "Show only individually added students";
              transform: translateX(11em);
            }
          }
          &.active {
            color: $white;
            background-color: $orange;
            &:hover {
              color: $silver;
              &::after {
                content: "Show all students";
                transform: translateX(6em);
              }
            }
          }
          
        }
      }

      @include e(group-list){
        ul {
          @extend .show-scroll;
          padding: $component-padding-sm;
          padding-top: 0;
          max-height: calc(100vh - #{$header-height-standard} - #{$footer-height-standard} - 260px);
          li {
            display: flex;
            justify-content: space-between;
            span {
              padding: $component-padding-xs;
            }
            i {
              margin-left: $component-spacing-sm;
              color: $gray;
              &.fa-trash {
                cursor: pointer;
                &:hover {
                  color: $navy;
                }
              }
            }
          }
        }
      }

      @include e(pagination){
        display: flex;
        justify-content: flex-end;
        padding: $component-padding-sm;
        .pagination__react-paginate {
          font-size: 1.25em;
        }
      }
    }

    @include e(search-view){
      @include e(search){
        padding-top: $component-padding-md;
        font-size: 1.1em;
      }
      @include e(results){
        ul {
          padding-top: $component-padding-sm;
          li {
            display: flex;
            justify-content: space-between;
            padding: $component-padding-xs $component-padding-md;
            i {
              color: $gray;
              cursor: pointer;
              &:hover {
                color: $navy;
              }
            }
          }
        }
      }
    }
  }

}